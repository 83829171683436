/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanyUserService } from './company-user.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/shared/layout/modal/modal.service';
import { ACCOUNT_TYPES, AccountType } from '../../../shared/account-types.factory';
import { User } from '../../../../auth/user';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { of, Observable, forkJoin } from 'rxjs';
import { tap, map, concatMap, mergeMap } from 'rxjs/operators';
import { Team } from '../../../shared/entities/team';
import {
  FormEditInput,
  FormEditInputSelect,
  FormEditInputSwitch,
  FormModalParameters,
} from 'src/app/shared/layout/modal/form-modal/form-modal-parameters';
import { CompanyTeamService } from '../../company-teams/services/company-team.service';
import { LanguageService } from 'src/app/shared/business/languages/language.service';
import { UploadFileService } from 'src/app/shared/upload/upload-file.service';
import { RecruitmentModeService } from 'src/app/shared/business/recruitments/recruitment-mode/recruitment-mode.service';
import { CompanyService } from '../../services/company.service';
import { RequiredTrueArrayValidator } from 'src/app/shared/form-controls/required-true-array.validator';
import { ConditionalValidator } from 'src/app/shared/form-controls/conditional.validator';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { REGEX_PASSWORD } from '../../../../shared/form-controls/regex';

@Injectable()
export class CompanyUserActionsService {
  private recruitmentModes: { key: string; value: string }[] = [];

  private inputFirstname = {
    key: 'firstname',
    type: 'text',
    label: this.translate.instant('PANEL.USER.EDITER.PROFIL.PRENOM'),
    validation: Validators.required,
    classes: { formGroup: 'col-sm-12' },
  } as FormEditInput;

  private inputLastname = {
    key: 'lastname',
    type: 'text',
    label: this.translate.instant('PANEL.USER.EDITER.PROFIL.NOM'),
    validation: Validators.required,
    classes: { formGroup: 'col-sm-12' },
  } as FormEditInput;

  private inputLanguage = {
    key: 'language',
    type: 'select',
    label: this.translate.instant('PANEL.USER.EDITER.PROFIL.LANGUE'),
    validation: Validators.required,
    classes: { formGroup: 'col-sm-6' },
    options: {
      id: 'id',
      useTooltip: false,
      items: this.languageService
        .getRecruiterLanguages()
        .map(t => ({ id: t.value, name: t.label, avatar: `/assets/images/flags/${t.value}.png` })),
      search: ['name', 'id'],
    },
  } as FormEditInput;

  constructor(
    private readonly translate: TranslateService,
    private companyUserService: CompanyUserService,
    private toasterService: ToastrService,
    private modalService: ModalService,
    private teamService: CompanyTeamService,
    private languageService: LanguageService,
    private uploadFileService: UploadFileService,
    private companyService: CompanyService,
    private authService: AuthService,
    private readonly companyTeamService: CompanyTeamService,
    recruitmentModeService: RecruitmentModeService,
    @Inject(ACCOUNT_TYPES) private accountTypes: AccountType[],
  ) {
    this.recruitmentModes = recruitmentModeService.getAll().map(m => ({ key: m.value, value: m.label }));
  }

  public create(user: Partial<User>, options: Partial<FormModalParameters> = {}) {
    const parameters: Partial<FormModalParameters> = {
      labels: {
        title: this.translate.instant('PANEL.USER.CREER.TITLE'),
      },
    };
    return this.edit(user as User, { ...parameters, ...options });
  }

  public edit(user: User, options: Partial<FormModalParameters> = {}): Observable<User> {
    return forkJoin({
      me: this.authService.me(),
      company: this.companyService.get(user.companyId),
      companyTeams: this.companyTeamService.getAll(user.companyId),
    }).pipe(
      mergeMap(({ me, company, companyTeams }) => {
        const companySsoMandatory = user.teams?.find(t => t.options?.isSsoMandatory) ?? company?.options?.isSsoMandatory;
        const companySsoKey = user.teams?.find(t => t.options?.ssoKey)?.options?.ssoKey ?? company?.options?.ssoKey;
        const ssoKeys = environment.ssoKeys.map(k => ({ id: k, name: k }));
        const teams = this.getTeams(me, companyTeams.data);

        if (user.options?.ssoKey) {
          ssoKeys.push({ id: user.options?.ssoKey, name: user.options?.ssoKey });
        }
        if (companySsoKey && companySsoKey != user.options?.ssoKey) {
          ssoKeys.push({ id: companySsoKey, name: companySsoKey });
        }
        const parameters: Partial<FormModalParameters> = {
          style: 'scrollable',
          labels: {
            title: this.translate.instant('PANEL.USER.EDITER.TITLE'),
          },
          defaultValues: {
            ...user,
            password: undefined,
            'upload-avatar': { url: user.avatar, base: this._appendToFileName(user.avatar, 'base') },
            'upload-logo': { url: user.logo, base: this._appendToFileName(user.logo, 'base') },
            useSso: user.externalId ?? user.options?.isSsoMandatory ?? companySsoMandatory,
            isSsoMandatory: user.options?.isSsoMandatory ?? companySsoMandatory,
            ssoKey: user.options?.ssoKey,
            recruitmentModes: user.options?.recruitmentModes,
            teamId: teams.length > 0 && user.teams?.length > 0 ? user.teams[0].id : undefined,
          },
          inputs: [
            { type: 'header', label: this.translate.instant('PANEL.USER.EDITER.PROFIL.TITLE') } as FormEditInput,
            this.inputFirstname,
            this.inputLastname,
            {
              key: 'email',
              type: 'text',
              disableAutofill: true,
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.EMAIL'),
              validation: Validators.compose([Validators.required, Validators.email]),
              classes: { formGroup: 'col-sm-6' },
            } as FormEditInput,
            {
              key: 'password',
              type: 'password',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.MOT_DE_PASSE'),
              generator: true,
              validation: Validators.compose([!user.id ? Validators.required : undefined, REGEX_PASSWORD]),
              classes: { formGroup: 'col-sm-6' },
            } as FormEditInput,
            {
              type: 'info',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.MOT_DE_PASSE.INFO_STRUCTURE'),
              classes: { formGroup: 'offset-sm-6 col-sm-6 pt-0 pb-2' },
            },
            this.inputLanguage,
            {
              key: 'phone',
              type: 'text',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.TELEPHONE'),
              classes: { formGroup: 'col-sm-6' },
            } as FormEditInput,
            {
              key: 'position',
              type: 'text',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.FONCTION'),
              validation: Validators.required,
              classes: { formGroup: 'col-sm-6' },
            } as FormEditInput,
            {
              key: 'teamId',
              type: 'select',
              label: this.translate.instant('PANEL.COMPANY.CONTENT.PROFILES.TEAM.LABEL'),
              classes: { formGroup: 'col-sm-6' },
              validation: Validators.required,
              options: {
                id: 'id',
                showThumbnail: false,
                items: teams.map((mTeam: Team) => ({ id: mTeam.id, name: mTeam.name })),
                search: ['name', 'id'],
              },
            } as FormEditInputSelect,
            {
              key: 'companyName',
              description: company?.name,
              type: 'text',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.NOM_COMPANIE'),
              classes: { formGroup: 'col-sm-6' },
            } as FormEditInput,
            {
              key: 'accountType',
              type: 'select',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.ROLE'),
              validation: Validators.required,
              classes: { formGroup: 'col-sm-6' },
              options: {
                id: 'id',
                showThumbnail: false,
                items: this.accountTypes.map(t => ({ id: t.value, name: t.label })),
                search: ['name', 'id'],
              },
            } as FormEditInputSelect,
            company?.options.recruitmentModes?.length > 1
              ? ({
                  key: 'recruitmentModes',
                  type: 'checkboxes',
                  label: this.translate.instant('PANEL.USER.EDITER.PROFIL.RECRUITMENT_MODES'),
                  validation: RequiredTrueArrayValidator,
                  classes: { formGroup: 'col-sm-6' },
                  options: {
                    id: 'id',
                    items: this.recruitmentModes.filter(r => company?.options.recruitmentModes.indexOf(r.key) > -1),
                    search: ['name', 'id'],
                  },
                } as FormEditInput)
              : undefined,
            { type: 'header', label: this.translate.instant('PANEL.USER.EDITER.PHOTOS.TITLE') },
            {
              key: 'upload-avatar',
              type: 'picture',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.AVATAR'),
              description: this.translate.instant('PANEL.USER.EDITER.PROFIL.AVATAR.DESCRIPTION'),
              classes: { formGroup: 'col-sm-6' },
              options: {
                icon: 'add_a_photo',
                accept: 'image/*',
              },
            } as FormEditInput,
            {
              key: 'upload-logo',
              type: 'picture',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.LOGO_ENTREPRISE'),
              description: this.translate.instant('PANEL.USER.EDITER.PROFIL.LOGO_ENTREPRISE.DESCRIPTION'),
              classes: { formGroup: 'col-sm-6' },
              isEditable: false,
              options: {
                icon: 'add_photo_alternate',
                accept: 'image/*',
                resizer: {
                  enableResize: true,
                },
              },
            } as FormEditInput,
            { type: 'header', label: this.translate.instant('PANEL.USER.EDITER.OPTIONS_AVANCES.TITLE') },
            {
              key: 'useSso',
              type: 'switch',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.USE_SSO'),
              disable: !me.isCsm && companySsoMandatory,
              classes: {},
            } as FormEditInputSwitch,
            {
              key: 'isSsoMandatory',
              type: 'switch',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.IS_SSO_MANDATORY'),
              disable: !me.isCsm && companySsoMandatory,
              onlyIf: (formGroup: UntypedFormGroup) => companySsoMandatory && formGroup.get('useSso').value,
              classes: {},
            } as FormEditInputSwitch,
            {
              key: 'ssoKey',
              type: 'select',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.SSO_KEY'),
              disable: !me.isCsm && !!companySsoKey,
              description: companySsoKey,
              validation: ConditionalValidator(g => g.get('useSso').value && !companySsoKey, Validators.required),
              onlyIf: (formGroup: UntypedFormGroup) => me.isCsm && formGroup.get('useSso').value,
              classes: { formGroup: 'col-sm-6' },
              options: {
                id: 'id',
                useTooltip: false,
                items: ssoKeys,
                showThumbnail: false,
                addTag: (k: string) => ({ id: k, name: k }),
                search: ['name', 'id'],
              },
            } as FormEditInputSelect,
            {
              key: 'externalId',
              type: 'text',
              label: this.translate.instant('PANEL.USER.EDITER.PROFIL.EXTERNAL_ID'),
              classes: { formGroup: 'col-sm-6' },
              validation: ConditionalValidator(g => g.get('useSso').value, Validators.required),
              onlyIf: (formGroup: UntypedFormGroup) => formGroup.get('useSso').value,
            } as FormEditInput,
          ].filter(v => !!v),
          onSubmit: result => {
            let isSsoMandatory = undefined;
            let ssoKey = '';
            if (result.useSso) {
              if (companySsoMandatory) {
                isSsoMandatory = me.isCsm && !result.isSsoMandatory ? false : false;
              } else {
                isSsoMandatory = result.isSsoMandatory ? true : false;
              }
              ssoKey = result.ssoKey;
            } else {
              if (me.isCsm) {
                isSsoMandatory = false;
              }
              result.externalId = null;
            }

            const recruitmentModes = result.recruitmentModes;

            if (result.teamId) {
              const team = teams.find((fTeam: Team) => fTeam.id === result.teamId);

              delete team.members;
              result.teams = [team];
              delete result.teamId;
            }

            delete result.isSsoMandatory;
            delete result.ssoKey;
            delete result.useSso;
            delete result.recruitmentModes;

            return this.save(
              {
                ...result,
                id: user.id,
                options: { ...user.options, isSsoMandatory, ssoKey, recruitmentModes },
              },
              user,
            );
          },
        } as FormModalParameters;
        return this.modalService.form({
          ...parameters,
          ...options,
        } as FormModalParameters);
      }),
    );
  }

  public editName(user: User, options: Partial<FormModalParameters> = {}): Observable<User> {
    const parameters: Partial<FormModalParameters> = {
      backdrop: 'static',
      defaultValues: { ...user },
      hasNotBtnCancel: true,
      inputs: [this.inputFirstname, this.inputLastname].filter(v => !!v),
      onSubmit: result => {
        return this.save({ ...result, id: user.id }, user);
      },
    } as FormModalParameters;
    return this.modalService.form({
      ...parameters,
      ...options,
    } as FormModalParameters);
  }

  public save(user: Partial<User>, currentUser: Partial<User> = user): Observable<User> {
    user.id = currentUser.id;
    return of(undefined).pipe(
      concatMap(_ => this._uploadFile(user, currentUser, 'avatar')),
      concatMap(_ => this._uploadFile(user, currentUser, 'logo')),
      concatMap(_ => this.saveUser(user, currentUser)),
    );
  }

  public updateUser(user: Partial<User>, currentUser: Partial<User>): Observable<User> {
    return this.companyUserService.updateUser(user, user.id).pipe(
      map(u => ({ ...currentUser, ...u }) as User),
      tap(() => {
        this.toasterService.success(
          this.translate.instant('PANEL.USER.MISE_A_JOUR.SUCCESS.BODY', { ...currentUser, ...user }),
          this.translate.instant('PANEL.USER.MISE_A_JOUR.SUCCESS.TITLE'),
        );
      }),
    );
  }

  public saveUser(user: Partial<User>, currentUser: Partial<User>): Observable<User> {
    let successTitle: string;
    let successDescription: string;
    if (!user.id) {
      successTitle = this.translate.instant('PANEL.USER.CREER.SUCCESS.TITLE');
      successDescription = this.translate.instant('PANEL.USER.CREER.SUCCESS.BODY', { ...currentUser, ...user });
    } else {
      user.accountType ??= currentUser.accountType;
      successTitle = this.translate.instant('PANEL.USER.MISE_A_JOUR.SUCCESS.TITLE');
      successDescription = this.translate.instant('PANEL.USER.MISE_A_JOUR.SUCCESS.BODY', { ...currentUser, ...user });
    }

    const saveUser$ = user.id
      ? this.companyUserService.patch(user, currentUser.companyId)
      : this.companyUserService.save(user, currentUser.companyId);

    return saveUser$.pipe(
      map(u => Object.assign(new User(), { ...currentUser, ...u })),
      tap(() => {
        this.toasterService.success(successDescription, successTitle);
      }),
    );
  }

  private _uploadFile(user: Partial<User>, currentUser: Partial<User>, attribute: 'avatar' | 'logo') {
    const picture = user['upload-' + attribute];

    if (!picture) {
      return of(undefined);
    }

    user['upload-' + attribute] = undefined;
    if (picture['url'] === currentUser[attribute]) {
      return of(undefined);
    }

    if (!picture['url']) {
      user[attribute] = null;
      return of(undefined);
    }

    const variants = { base: picture['base'] };

    const uploadObservable = this.uploadFileService
      .upload(picture['url'], attribute + '.png', variants)
      .pipe(tap(r => (user[attribute] = r.file.signedUrl)));
    return uploadObservable;
  }

  private _appendToFileName(filename: string, append: string, separator: string = '-') {
    if (!filename) {
      return filename;
    }
    const parts = filename.split('.');
    const extension = parts.pop();

    return `${parts.join('.')}${separator}${append}.${extension}`;
  }

  public bulk(users: User[], companyId: number) {
    if (users.length === 1) {
      return this.save({ ...users[0], companyId });
    }
    const successTitle = this.translate.instant('PANEL.USERS.MISE_A_JOUR.SUCCESS.TITLE');
    const successDescription = this.translate.instant('PANEL.USERS.MISE_A_JOUR.SUCCESS.BODY');

    return this.companyUserService.bulk(users, companyId).pipe(
      tap(() => {
        this.toasterService.success(successDescription, successTitle);
      }),
    );
  }

  public changeTeam(users: User | User[], companyId: number, currentTeamIds: number[] = []) {
    if (!Array.isArray(users)) {
      companyId = companyId || users.companyId;
      currentTeamIds = (users.teams || []).map(t => t.id);
      users = [users];
    }
    return this._changeTeam(users, companyId, currentTeamIds);
  }

  private _changeTeam(users: User[], companyId: number, currentTeamIds: number[] = []) {
    return this.modalService.form({
      labels: {
        warning: this.translate.instant('PANEL.USER.TEAMS.REMOVE.DISLCAIMER'),
        body: this.translate.instant('PANEL.USER.TEAMS.AJOUTER.TITLE', {
          count: users.length,
          firstname: users[0].firstname,
          lastname: users[0].lastname,
        }),
      },
      inputs: [
        {
          key: 'team',
          type: 'select',
          description: this.translate.instant('PANEL.USER.TEAMS.AJOUTER.SELECTIONNEZ_UNE_EQUIPE'),
          options: {
            multiple: false,
            showThumbnail: false,
            items$: this.teamService.getAll(companyId).pipe(map(r => r.data.filter(t => currentTeamIds.indexOf(t.id) === -1))),
            search: ['name'],
          },
        } as FormEditInput,
      ],
      onSubmit: result => {
        if (result && result.team) {
          if (users.length === 1) {
            const user = users[0];
            return this.save({ teams: [{ id: result.team.id, name: result.team.name } as Team] }, user);
          }
          return this.bulk(
            users.map(user => ({ id: user.id, teams: [{ id: result.team.id }] }) as User),
            companyId,
          );
        }
      },
    });
  }

  private getTeams(user: User, teams: Team[]): Team[] {
    if (user.isAdminTeam) {
      return user.administratedTeams;
    }

    if (user.isAdmin || user.isCsm) {
      return teams;
    }

    return [];
  }
}
