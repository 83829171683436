<div class="modal-header" data-cy="DIV-a4e5">
  <h4 class="modal-title m-auto text-center" data-cy="H4-3d2d">{{ parameters?.labels?.title }}</h4>
  <button *ngIf="!hasNotBtnCancel" type="button" class="close pull-right" aria-label="Close" (click)="cancel()" data-cy="BUTTON-638d">
    <span aria-hidden="true" data-cy="SPAN-06d9">&times;</span>
  </button>
</div>
<div class="modal-body" data-cy="DIV-ce1b">
  <form class="d-flex flex-column h-100 w-100" (ngSubmit)="onSubmit()" autocomplete="off">
    <fieldset [disabled]="isSubmitting || disabled">
      <p *ngIf="parameters?.labels?.body" class="text-secondary text-center">{{ parameters?.labels?.body }}</p>
      <div
        *ngIf="parameters?.labels?.info"
        class="alert alert-success"
        role="alert"
        [innerHTML]="parameters?.labels?.info"
        data-cy="DIV-e01b"
      ></div>
      <div *ngIf="parameters?.labels?.warning" class="alert alert-warning row" role="alert" data-cy="DIV-e01b">
        <div class="col-1 m-auto">⚠️</div>
        <div class="col-11" [innerHTML]="parameters?.labels?.warning"></div>
      </div>
      <div class="row g-2" data-cy="DIV-e7da">
        <ng-container *ngFor="let input of parameters?.inputs; let index = index" [ngSwitch]="input.type">
          <ng-container>
            <ng-container
              *ngSwitchCase="'select'"
              [ngTemplateOutlet]="defaultSelect"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'header'"
              [ngTemplateOutlet]="defaultHeader"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'info'"
              [ngTemplateOutlet]="defaultInfo"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'password'"
              [ngTemplateOutlet]="defaultPassword"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index, type: 'password' }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'picture'"
              [ngTemplateOutlet]="defaultPicture"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index, type: 'picture' }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'checkboxes'"
              [ngTemplateOutlet]="defaultCheckboxes"
              [ngTemplateOutletContext]="{
                input: input,
                parameters: parameters,
                formControl: formControl,
                index: index,
                type: 'checkboxes'
              }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'switch'"
              [ngTemplateOutlet]="defaultSwitch"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index, type: 'switch' }"
            ></ng-container>
            <ng-container
              *ngSwitchDefault
              [ngTemplateOutlet]="defaultInput"
              [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index, type: 'text' }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="text-center mt-3" *ngIf="!disabled" data-cy="DIV-0a4d">
        <button type="submit" class="btn btn-panel px-5" data-cy="BUTTON-f792">{{ parameters?.labels?.submit }}</button>
        <br />
        <button
          *ngIf="!hasNotBtnCancel"
          type="button"
          class="btn btn-link btn-sm text-decoration-none p-3"
          (click)="cancel()"
          data-cy="BUTTON-4acb"
        >
          {{ parameters?.labels?.cancel }}
        </button>
      </div>
    </fieldset>
  </form>
</div>

<ng-template #defaultHeader let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index">
  <div
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    class="col-12 pt-2 {{ input.classes?.formGroup }}"
    [formGroup]="formControl"
    data-cy="DIV-c168"
  >
    <h6 [innerHTML]="input.label" data-cy="H6-84c1"></h6>
  </div>
</ng-template>

<ng-template #defaultInfo let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index">
  <div
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    class="col-12 pt-2 {{ input.classes?.formGroup }}"
    [formGroup]="formControl"
    data-cy="DIV-cfe1"
  >
    <small [innerHTML]="input.label" data-cy="SMALL-7c24"></small>
  </div>
</ng-template>

<ng-template #defaultSwitch let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index">
  <div
    class="col-12 {{ input.classes?.formGroup }}"
    [formGroup]="formControl"
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    [class.required]="isRequired(formControl, input)"
    data-cy="DIV-f039"
  >
    <div class="form-check form-switch" data-cy="DIV-2180">
      <input
        [autofocus]="index === 0"
        [id]="input.key"
        class="form-check-input"
        [formControlName]="input.key"
        type="checkbox"
        [class.is-invalid]="formControl.isInvalid(input.key)"
        [placeholder]="input.description || ''"
        [attr.disabled]="input.disable ? true : undefined"
        autocomplete="custom-input"
        data-cy="INPUT-3535"
      />
      <label *ngIf="input.label" class="text-nowrap text-secondary fw-normal form-check-label" [for]="input.key" data-cy="LABEL-a9f6">{{
        input.label
      }}</label>

      <ng-container
        [ngTemplateOutlet]="defaultError"
        [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #defaultInput let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index" let-type="type">
  <div
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    class="col-12 {{ input.classes?.formGroup }}"
    [formGroup]="formControl"
    [class.required]="isRequired(formControl, input)"
    data-cy="DIV-1c04"
  >
    <label *ngIf="input.label" class="text-secondary fw-normal form-label" [for]="input.key" data-cy="LABEL-e801">
      <span class="text-nowrap" data-cy="SPAN-bbec">{{ input.label }} :</span>
    </label>

    <input
      [autofocus]="index === 0"
      [id]="input.key"
      class="form-control"
      [formControlName]="input.key"
      [type]="type"
      [class.is-invalid]="formControl.isInvalid(input.key)"
      [placeholder]="input.description || ''"
      [readOnly]="input.disableAutofill ? 'readonly' : ''"
      (focus)="input.disableAutofill = false"
      autocomplete="custom-input"
      data-cy="INPUT-977f"
    />
    <ng-container
      [ngTemplateOutlet]="defaultError"
      [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultPassword let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index" let-type="type">
  <div
    class="col-12 {{ input.classes?.formGroup }}"
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    [formGroup]="formControl"
    [class.required]="isRequired(formControl, input)"
    data-cy="DIV-686c"
  >
    <label *ngIf="input.label" class="text-secondary fw-normal form-label" [for]="input.key" data-cy="LABEL-15c2">
      <span class="text-nowrap" data-cy="SPAN-5954">{{ input.label }} :</span>
    </label>
    <div class="input-group" data-cy="DIV-de03">
      <input
        [autofocus]="index === 0"
        [id]="input.key"
        class="form-control"
        [formControlName]="input.key"
        [type]="input.show ? 'text' : 'password'"
        [class.is-invalid]="formControl.isInvalid(input.key)"
        [placeholder]="input.description || ''"
        autocomplete="custom-input"
        data-cy="INPUT-6575"
      />
      <button
        *ngIf="!input.show"
        (click)="input.show = true"
        class="input-group-text"
        title="Afficher"
        [title]="'COMMON.DISPLAY' | translate"
        data-cy="BUTTON-a920"
      >
        <i class="material-icons" data-cy="I-aa34">visibility</i>
      </button>
      <button
        *ngIf="input.show"
        (click)="input.show = false"
        class="input-group-text"
        title="Masquer"
        [title]="'COMMON.HIDE' | translate"
        data-cy="BUTTON-329a"
      >
        <i class="material-icons" data-cy="I-fdfa">visibility_off</i>
      </button>
      <button
        *ngIf="input.generator"
        class="input-group-text"
        (click)="input.show = true; generatePassword(input)"
        title="Générer"
        [title]="'COMMON.GENERATE' | translate"
        data-cy="BUTTON-b9f6"
      >
        <i class="material-icons" data-cy="I-38e3">autorenew</i>
      </button>
    </div>
    <ng-container
      [ngTemplateOutlet]="defaultError"
      [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template
  #defaultCheckboxes
  let-input="input"
  let-parameters="parameters"
  let-formControl="formControl"
  let-index="index"
  let-type="type"
>
  <div
    class="col-12 {{ input.classes?.formGroup }}"
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    [class.required]="isRequired(formControl, input)"
    [formGroup]="formControl"
    data-cy="DIV-b705"
  >
    <label *ngIf="input.label" class="text-secondary fw-normal form-label" [for]="input.key" data-cy="LABEL-ea21">
      <span class="text-nowrap" data-cy="SPAN-f498">{{ input.label }} :</span>
    </label>
    <div class="col col-sm-8 mb-2" *ngFor="let control of formControl.get(input.key)?.controls; let i = index" data-cy="DIV-967b">
      <div class="form-control" data-cy="DIV-62f5">
        <div class="form-check" data-cy="DIV-2c5f">
          <input
            id="recruitmentMode_{{ i }}"
            name="recruitmentMode_{{ i }}"
            type="checkbox"
            class="form-check-input"
            [class.is-invalid]="formControl.isInvalid(input.key)"
            [value]="input.options.items[i].key"
            [formControl]="control"
            data-cy="INPUT-195d"
          />
          <label for="recruitmentMode_{{ i }}" class="form-check-label" data-cy="LABEL-a49d">{{ input.options.items[i].value }}</label>
        </div>
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="defaultError"
      [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultPicture let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index" let-type="type">
  <div
    class="col-12 {{ input.classes?.formGroup }}"
    [formGroup]="formControl"
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    data-cy="DIV-a308"
  >
    <label *ngIf="input.label" class="text-secondary fw-normal form-label" [for]="input.key" data-cy="LABEL-1acd">
      <span class="text-nowrap" data-cy="SPAN-2565">{{ input.label }} :</span>
    </label>
    <div class="text-center text-sm-start" data-cy="DIV-709e">
      <app-resource-viewer
        *ngIf="formControl.get(input.key)?.value?.url"
        [isPreviewable]="false"
        [resource]="{ previewUrl: formControl.get(input.key)?.value?.url, type: 'image', status: 'converted' }"
        [isEditable]="input.isEditable"
        (edit)="editImage(input)"
        (delete)="deleteImage(input)"
      >
      </app-resource-viewer>
      <div
        *ngIf="!formControl.get(input.key)?.value?.url"
        ngfDrop
        #modal="ngfDrop"
        selectable="1"
        [(validDrag)]="input.validUploadDrag"
        [(lastBaseUrl)]="input.currentFileUrl"
        [accept]="input.options?.accept"
        [maxSize]="(input.options?.maxSize || 1000) * 1024 * 1024"
        class="text-center"
        [class.invalid]="input?.validUploadDrag === false"
        [class.valid]="input?.validUploadDrag"
        (lastBaseUrlChange)="upload($event, input, modal)"
        (lastInvalidsChange)="$event?.length && onUploadInvalidFile()"
        data-cy="DIV-8124"
      >
        <i class="material-icons md-36" data-cy="I-3011">{{ input.options?.icon || 'add_to_photos' }}</i>
        <br />
        <small data-cy="SMALL-05ab">{{ input.description }}</small
        >{{ input.files | json }}
      </div>
    </div>
    <ng-container
      [ngTemplateOutlet]="defaultError"
      [ngTemplateOutletContext]="{ input: input, parameters: parameters, formControl: formControl, index: index }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultSelect let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index">
  <div
    class="col-12 {{ input.classes?.formGroup }}"
    *ngIf="input.onlyIf === undefined || input.onlyIf(formControl)"
    [formGroup]="formControl"
    [class.required]="isRequired(formControl, input)"
    [class.is-invalid]="formControl.isInvalid(input.key)"
    data-cy="DIV-af37"
  >
    <label
      *ngIf="input.label && !input.options.customLabelTemplate"
      class="text-secondary fw-normal form-label"
      [for]="input.key"
      data-cy="LABEL-f35a"
    >
      <span class="text-nowrap" data-cy="SPAN-dc07">{{ input.label }} :</span>
    </label>
    <ng-container *ngIf="input.options.customLabelTemplate" [ngTemplateOutlet]="input.options.customLabelTemplate"></ng-container>
    <ng-select
      name="userIds"
      class="ngselect"
      [formControlName]="input.key"
      [class.is-invalid]="formControl.isInvalid(input.key)"
      [multiple]="input.options?.multiple"
      [addTag]="input.options?.addTag"
      [addTagText]="input.options?.addTagText"
      [closeOnSelect]="!input.options?.multiple"
      [items]="input.options?.items"
      [loading]="input.options?.items === undefined"
      [bindValue]="input.options?.id"
      [placeholder]="input.description || ''"
      [searchFn]="customSearch(input.options?.search)"
      [hideSelected]="true"
      [readonly]="disabled || input.disable"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <cc-user-thumbnail
          *ngIf="input.options?.showThumbnail !== false"
          [size]="29"
          class="me-2 align-middle"
          [thumbnail]="{
            url: item.avatar,
            useTooltip: input.options?.useTooltip === false ? false : true,
            label: item.name || item.firstname + ' ' + item.lastname
          }"
        ></cc-user-thumbnail>
        <span class="align-middle" data-cy="SPAN-3d3a"> {{ item?.firstname }} {{ item?.lastname }} {{ item?.name }} </span>
        <span class="ng-value-icon" (click)="clear.call(undefined, item)" aria-hidden="true" data-cy="SPAN-86de">×</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-clear="clear">
        <cc-user-thumbnail
          *ngIf="input.options?.showThumbnail !== false"
          [size]="29"
          class="me-2 align-middle"
          [thumbnail]="{
            url: item.avatar,
            useTooltip: !(input.options?.useTooltip === false),
            label: item.name || item.firstname + ' ' + item.lastname
          }"
        ></cc-user-thumbnail>
        <span class="align-middle" data-cy="SPAN-e50c"> {{ item?.firstname }} {{ item?.lastname }} {{ item?.name }} </span>
      </ng-template>
    </ng-select>
    <ng-container
      *ngTemplateOutlet="defaultError; context: { input: input, parameters: parameters, formControl: formControl, index: index }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultError let-input="input" let-parameters="parameters" let-formControl="formControl" let-index="index">
  <div
    *ngIf="formControl.get(input.key).errors?.required"
    class="invalid-feedback"
    [translate]="'COMMON.ERRORS.REQUIRED'"
    data-cy="DIV-1de8"
  ></div>
  <div
    *ngIf="!!formControl.get(input.key).errors?.pattern"
    class="invalid-feedback"
    [translate]="'COMMON.ERRORS.UNVALID'"
    data-cy="DIV-9155"
  ></div>
</ng-template>
